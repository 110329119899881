// 404.js
export default function FourOhFour() {
  return <>
    <div style={{display:"flex", justifyContent:"center", alignItems:"center", height:"50em",}}>
        <div style={{display:"flex", flexDirection:"column", alignItems:"center", lineHeight:-10}}>
            <h1 style={{fontSize:100 , fontWeight:"900", marginBottom:0, color:"#C1C7C9"}}>404!</h1>
            {/* <h3>Not Found</h3> */}
            <div style={{width:"70%", display:"flex", textAlign:"center",  }}>
                <h5 style={{fontSize:16, fontWeight:500, lineHeight:1.5, color:"#898989"}}>Sorry, we couldn't find this page. But don't worry, you can find plenty of things on our <a href='/' style={{color:"#005fcf", textDecoration:"none"}}>homepage</a></h5>
            </div>
        </div>
    </div>
  </>
}